.contact {
  padding: rem(50px 0);
}

.contact .alert {
  margin: 0 auto 30px auto;
  text-align: center;
  padding: 20px;
  border: 2px solid $theme-red;
}

.contact__intro {
  text-align: center;
  margin-bottom: rem(20px);
}

.contact input {
  border: 1px solid #dadada;
  height: rem(44px);
  width: 100%;
  margin-bottom: rem(15px);
  padding: rem(0 15px);
  border-radius: 0!important;
  box-shadow: none!important;
  -webkit-appearance: none;
}

.contact textarea {
  border: 1px solid #dadada;
  height: rem(200px);
  width: 100%;
  margin-bottom: rem(15px);
  padding: rem(59px 15px 15px 15px);
  border-radius: 0!important;
  box-shadow: none!important;
  -webkit-appearance: none;
}

.contact__form-btn {
  background: none;
  width: 100%;
  border-color: $theme-secondary;
  color: $theme-secondary;
}

.contact__form-btn:hover {
  background: $theme-secondary;
  color: #FFF;
  border-color: $theme-secondary
}

.input-wrapper {
  display: block;
  position: relative;
}

.input-wrapper label {
  position: absolute;
  height: rem(44px);
  width: 100%;
  text-align: left;
  background: transparent;
  top: 0;
  right: 0;
  padding-left: rem(15px);
  line-height: rem(44px);
  font-weight: normal;
  transition: all 0.3s;
}

.input-wrapper textarea:focus,
.input-wrapper input:focus {
  outline: none;
  border-color: $theme-secondary;
}

.input-wrapper input.hasUserInput + label,
.input-wrapper input:focus + label {
  width: 30%;
  background-color: $theme-secondary;
  color: #FFF;
  font-size: rem(12px);
}

.input-wrapper textarea.hasUserInput + label,
.input-wrapper textarea:focus + label {
  background-color: $theme-secondary;
  color: #FFF;
  font-size: rem(12px);
}

.contact__contactlist {
  margin: rem(0 0 50px 0)!important;
  padding: 0;
}

.contact__contactlist-item {
  list-style-type: none!important;
  width: 100%;
  margin-bottom: rem(10px);
}

.contact__contactlist-icon {
  position: relative;
  display: inline-block;
  width: rem(24px);
  height: rem(24px);
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  top: rem(6px);
  margin-right: rem(10px);
}

.contact__map {
  margin-bottom: rem(50px);
}

.contact__map * {
  color: $text-color-dark!important;
}

.contact__map a,
.contact__map a * {
  color: $theme-secondary!important;
}

.contact ul li:before {
  content: none!important;
}

.contact.contact--bgcolor {
  background-color: $theme-secondary;
}

.contact.contact--bgcolor * {
  color: #fff;
}

.contact.contact--bgcolor .contact__contactlist-item,
.contact.contact--bgcolor .contact__contactlist-icon {
  color: #fff;
}

.contact.contact--bgcolor .contact__form-btn {
  border-color: #fff;
  color: #fff;
}

.contact.contact--bgcolor .input-wrapper input {
  border-color: darken($theme-secondary, 5%);
  background-color: darken($theme-secondary, 5%);
}

.contact.contact--bgcolor .input-wrapper textarea {
  border-color: darken($theme-secondary, 5%);
  background-color: darken($theme-secondary, 5%);
}

.contact.contact--bgcolor .input-wrapper input.hasUserInput+label,
.contact.contact--bgcolor .input-wrapper input:focus+label {
  background-color: #FFF;
  color: darken($theme-secondary, 5%);
}

.contact.contact--bgcolor .input-wrapper textarea.hasUserInput+label,
.contact.contact--bgcolor .input-wrapper textarea:focus+label {
  background-color: #FFF;
  color: darken($theme-secondary, 5%);
}

.contact__form {
  max-width: rem(800px);
  margin: 0 auto;
}

.contact.contact--map .contact__contactlist-item {
  text-align: center;
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {

  .input-wrapper input.hasUserInput + label,
  .input-wrapper input:focus + label {
    font-size: rem(16px);
  }

  .input-wrapper textarea.hasUserInput + label,
  .input-wrapper textarea:focus + label {
    font-size: rem(16px);
  }

  .contact__form-btn {
    width: rem(250px);
  }

  .contact__map {
    margin-bottom: rem(100px);
  }

  .contact__contactlist {
    margin: 0!important;
    padding: 0;
  }

  .contact.contact--nomap .contact__contactlist {
    width: 100%;
    max-width: rem(800px);
    text-align: center;
    margin-left: auto!important;
    margin-right: auto!important;
  }

  .contact.contact--nomap .contact__contactlist-item {
    width: 33.3333%;
    float: left;
  }

  .flexbox .contact.contact--nomap .contact__contactlist {
    display: flex;
    justify-content: space-around;
    margin-bottom: rem(20px)!important;
  }

  .flexbox .contact.contact--nomap .contact__contactlist-item {
    width: auto;
    float: none;
  }

  .contact.contact--nomap .contact__form-btn {
    margin: 0 auto;
  }

}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {

  .contact input {
    height: rem(60px);
  }

  .input-wrapper label {
    height: rem(60px);
    line-height: rem(60px);
  }

  .contact__form-btn {
    background: none;
    width: rem(300px);
    height: rem(60px);
  }

  .input-wrapper label {
    height: rem(60px);
  }

  .input-wrapper input.hasUserInput + label,
  .input-wrapper input:focus + label {
    width: 25%;
  }

  .contact textarea {
    height: rem(220px);
    padding: rem(75px 15px 15px 15px);
  }

  .contact {
    padding: rem(75px 0);
  }

}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {

  .contact input {
    margin-bottom: rem(20px);
  }

  .contact textarea {
    height: rem(250px);
    margin-bottom: rem(20px);
  }

  .contact__map {
    margin-bottom: rem(90px);
  }

  .contact__form-btn {
    padding: rem(15px 12px)!important;
  }

  .contact {
    padding: rem(100px 0);
  }

}