// Color Configuration
$theme-primary: #ffffff;
$theme-secondary: #003382;
$text-color-light: #ffffff;
$text-color-dark: #3f3e40;
$headline-color: #003382;
$metanav-color: #003382;
$footer-color: #003382;
$footer-metanav-color: #002565;
$theme-red: #e2001a;

@font-face {
  font-family: 'Swis';
  src: url('../fonts/Swiss721BT-Roman.woff2') format('woff2'),
  url('../fonts/Swiss721BT-Roman.woff') format('woff'),
  url('../fonts/Swiss721BT-Roman.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Swis';
  src: url('../fonts/Swiss721BT-Bold.woff2') format('woff2'),
  url('../fonts/Swiss721BT-Bold.woff') format('woff'),
  url('../fonts/Swiss721BT-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Swis';
  src: url('../fonts/Swiss721BT-Black.woff2') format('woff2'),
  url('../fonts/Swiss721BT-Black.woff') format('woff'),
  url('../fonts/Swiss721BT-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

// Font Configuration
$font-family-base:      'Swis', Arial, Verdana, sans-serif;
$font-size-h1:          40px;
$font-size-h2:          30px;
$font-size-h3:          24px;
$font-size-h4:          18px;
$font-size-h5:          16px;
$font-size-base:        16px;
$font-size-base-large:  20px;


// Import Components
@import "_header";
@import "_mainpicture";
@import "_textpic";
@import "_cards";
@import "_hover-infobox";
@import "_linkbox";
@import "_article-list";
@import "_imagegallery";
@import "_hovergallery";
@import "_reference-slider";
@import "_contact";
@import "_footer";


