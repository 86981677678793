.mainpic {
  position: relative;
  width: 100%;
  height: rem(500px);
  //height: 100vh;
  overflow: hidden;
}

.mainpic--slider .slick-list, .mainpic--slider .slick-track {
  height: 100%;
}

.mainpic__container {
  position: relative;
  height: 100%;
}

.mainpic__item {
  position: relative;
  background-size: cover;
  background-position: center center;
  width: 100%;
  height: 100%;
}

.mainpic__itemoverlay {
  position: absolute;
  bottom: rem(70px);
  background-color: $theme-secondary;
  width: rem(300px);
  padding: rem(30px 10px 20px 10px);
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
}

.mainpic--transparent-overlay .mainpic__itemoverlay {
  background-color: rgba(0,0,0,0.5);
}

.mainpic__itemoverlay h1 {
  color: #fff;
}

.mainpic__itemoverlay p.large {
  color: #fff;
}

.mainpic--full-overlay .mainpic__container {
  position: static;
}

.mainpic--full-overlay .mainpic__itemoverlay {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  transform: none;
}

header + .mainpic.mainpic--full-overlay .mainpic__itemoverlay {
  top: rem(60px);
}

.mainpic--full-overlay .mainpic__itemoverlay-content {
  position: absolute;
  bottom: rem(100px);
  width: rem(280px);
  left: 50%;
  margin-left: rem(-140px);
}

.mainpic__itemoverlay-content .btn {
  margin: rem(20px auto 0 auto);
}

.mainpic__item:not(:first-of-type) {
  display: none;
}

.mainpic__item.mainpic--slider:not(:first-of-type) {
  display: block;
}

.mainpic--piconly-small .mainpic__itemoverlay,
.mainpic--piconly-mid .mainpic__itemoverlay,
.mainpic--piconly-700 .mainpic__itemoverlay,
.mainpic--piconly-large .mainpic__itemoverlay {
  display: none!important;
}

.mainpic.mainpic--piconly-small {
  height: rem(60px);
}

.mainpic.mainpic--piconly-small .slick-dots {
  display: none!important;
}

.mainpic.mainpic--piconly-mid  {
  height: rem(200px);
}

.mainpic.mainpic--piconly-700  {
  height: rem(350px);
}

.mainpic.mainpic--piconly-mid .slick-dots {
  display: none!important;
}

.headerimage {
  position: relative;
  background: $theme-secondary;
}

.headerimage__item {
  position: relative;
}

.headerimage__item img {
  margin: 0 auto;
}

.headerimage__overlaywrapper {
  background: $theme-secondary;
  padding: rem(15px 0px 25px 0px);

  h1, p, p.large {
    color: #fff;
  }

}

.headerimage__overlay {
  text-align: center;

  .btn {
    margin: 0 auto;
  }
}

.headerimage .slick-dots {
  padding: 0!important;
}

.headerimage.slick-dotted .headerimage__overlaywrapper {
  padding-bottom: rem(60px);
}

@media (max-width: 767px) {

  .headerimage.mainpic--slider .headerimage__overlay {
    padding-bottom: rem(40px);
  }



}


// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {

  .mainpic {
    height: rem(600px);
  }

  header + #content .mainpic {
    //top: rem(-150px);
    //margin-bottom: rem(-150px);
  }

  .mainpic__itemoverlay {
    width: rem(480px);
    padding: rem(60px 45px 30px 45px);
  }

  header + #content .mainpic.mainpic--full-overlay .mainpic__itemoverlay {
    //top: rem(150px);
  }

  .mainpic--full-overlay .mainpic__itemoverlay-content {
    bottom: rem(150px);
    width: rem(420px);
    margin-left: rem(-210px);
  }

  .mainpic--left-overlay .mainpic__itemoverlay {
    left: 10px;
    transform: none;
    text-align: left;
  }

  .mainpic--left-overlay .mainpic__itemoverlay .btn {
    margin: rem(20px 0 0 0);
  }

  .mainpic--right-overlay .mainpic__itemoverlay {
    left: auto;
    right: 10px;
    transform: none;
    text-align: left;
  }

  .mainpic--right-overlay .mainpic__itemoverlay .btn {
    margin: rem(20px 0 0 0);
  }

  .itemoverlay__container {
    position: relative;
    height: 100%;
    max-width: 100%;
  }

  .mainpic--full-overlay.mainpic--right-overlay .mainpic__itemoverlay {
    right: 0;
  }

  .mainpic--full-overlay.mainpic--right-overlay .mainpic__itemoverlay-content {
    left: auto;
    right: 0;
  }

  .mainpic--full-overlay.mainpic--left-overlay .mainpic__itemoverlay {
    left: 0;
  }

  .mainpic--full-overlay.mainpic--left-overlay .mainpic__itemoverlay-content {
    left: 0;
    right: auto;
    margin: 0;
  }

  .mainpic.mainpic--piconly-small {
    height: rem(150px);
  }

  .mainpic.mainpic--piconly-mid  {
    height: rem(300px);
  }

  .mainpic.mainpic--piconly-700  {
    height: rem(500px);
  }

  .mainpic.mainpic--piconly-mid .mainpic__item  {
    height: 100%;
  }

  .mainpic.mainpic--piconly-700 .mainpic__item  {
    height: 100%;
  }

}


@media (min-width: 768px) and (max-width: 991px) {
  .headerimage__overlay {
    h1 {
      font-size: rem(32px);
      margin-bottom: rem(10px);
    }
    p.large {
      font-size: rem(18px);
      line-height: 1.1;
      margin-bottom: rem(15px);
    }
    .btn {
      font-size: rem(18px);
    }
  }
}


// Large devices (desktops, 992px and up)
@media (min-width: 992px) {

  header + #content .mainpic {
    //top: rem(-200px);
    //margin-bottom: rem(-200px);
  }

  .mainpic__itemoverlay {
    width: rem(640px);
    padding: rem(60px 45px 30px 45px);
  }

  header + #content .mainpic.mainpic--full-overlay .mainpic__itemoverlay {
    //top: rem(200px);
  }

  .mainpic--full-overlay .mainpic__itemoverlay-content {
    bottom: rem(100px);
    width: rem(560px);
    margin-left: rem(-280px);
  }

  .mainpic--full-overlay.mainpic--right-overlay .mainpic__itemoverlay-content {
    width: rem(560px);
  }

  .mainpic.mainpic--piconly-small {
    height: rem(200px);
  }

  .mainpic.mainpic--piconly-mid  {
    height: rem(300px);
  }

  .mainpic.mainpic--piconly-700  {
    height: rem(600px);
  }

  .headerimage__overlay {
    width: rem(640px);
    padding: rem(45px 45px 30px 45px);
  }

  .headerimage__overlay {
    position: absolute;
    //left: 50%;
    //top: 50%;
    //transform: translate(-50%,-50%);
    background-color: rgba(0,0,0,0.5);
    width: rem(480px);
    padding: rem(45px 45px 30px 45px);
    text-align: center;
  }

  .headerimage__overlaywrapper {
    background: none;
    position: absolute;
    top: 0;
    left: rem(20px);
    width: 100%;
    height: 100%;
  }

  .headerimage__overlaywrapper .container {
    position: relative;
    height: 100%;
  }

  .headerimage__overlay .btn {
    margin-left: auto;
    margin-right: auto;
  }

  .headerimage {
    &.topleft .headerimage__overlay {
      top:rem(20px);right:auto;bottom:auto;left:0;text-align:left;
      .btn {margin-left:0;}
    }
    &.topcenter .headerimage__overlay {
      top:rem(20px);left:50%;transform:translateX(-50%);right:auto;bottom:auto;text-align:center;
      .btn {margin-left:0;}
    }
    &.topright .headerimage__overlay {
      top:rem(20px);right:0;bottom:auto;left:auto;text-align:left;
      .btn {margin-left:0;}
    }
    &.middleleft .headerimage__overlay {
      top:50%;transform:translateY(-50%);right:auto;bottom:auto;left:0;text-align:left;
      .btn {margin-left:0;}
    }
    &.middlecenter .headerimage__overlay {
      top:50%;right:auto;bottom:auto;left:50%;transform: translate(-50%,-50%);text-align:center;
    }
    &.middleright .headerimage__overlay {
      top:50%;transform:translateY(-50%);right:0;bottom:auto;left:auto;text-align:left;
      .btn {margin-left:0;}
    }
    &.bottomleft .headerimage__overlay {
      top:auto;right:auto;bottom:rem(50px);left:0;text-align:left;
      .btn {margin-left:0;}
    }
    &.bottomcenter .headerimage__overlay {
      top:auto;left:50%;transform:translateX(-50%);right:auto;bottom:rem(50px);text-align:center;
    }
    &.bottomright .headerimage__overlay {
      top:auto;right:0;bottom:rem(50px);left:auto;text-align:left;
      .btn {margin-left:0;}
    }
  }

}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {

  .mainpic {
    height: rem(700px);
  }

  header + #content .mainpic {
    //top: rem(-250px);
    //margin-bottom: rem(-250px);
  }

  .mainpic--full-overlay .mainpic__itemoverlay-content {
    bottom: rem(150px);
    width: rem(650px);
    margin-left: rem(-325px);
  }

  .mainpic--full-overlay.mainpic--right-overlay .mainpic__itemoverlay-content {
    width: rem(650px);
  }

  header + #content .mainpic.mainpic--full-overlay .mainpic__itemoverlay {
    //top: rem(250px);
  }

  .mainpic.mainpic--piconly-small {
    height: rem(250px);
  }

  .mainpic.mainpic--piconly-700  {
    height: rem(700px);
  }

  .mainpic.mainpic--piconly-mid  {
    height: rem(390px);
  }

  .headerimage__overlay {
    width: rem(640px);
  }

}