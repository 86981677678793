// Custom Project Styles
@import "jquery.fancybox.scss";
@import "connectiv/z-index.scss";
@import "connectiv/sprite.scss";
@import "connectiv/custom-vars.scss";
@import "connectiv/conn-basics.scss";

// Font-Awesome
@import "font-awesome/font-awesome.scss";

// Lightgallery Styles
@import "lightgallery/lightgallery";

//Bootstrap
//enthält in dieser Einstellung nur häufig benötigte Dateien (bei Bedarf zusätzliche Kompontenten in der _bootstrap.scss einkommentieren)
@import "bootstrap/bootstrap";

// Mixin import & Configuration
@import "mixins/mixins";
$rem-baseline: 10px;

// Theme Import
@import "theme/config";


//Slick Slider
@import "slick/slick.scss";
.slick-slide:focus { outline: none; }
.slick-arrow { position: absolute; border: none; background: transparent; font-size: 0; width: rem(44px); height: rem(44px); background-repeat: no-repeat; background-position: center; top: 50%; transform: translateY(-50%); z-index: 100; opacity: 0.5; transition: all 0.3s; outline: none;}
.slick-arrow:hover { opacity: 1; }
.slick-prev { left: 0; background-image: url('../img/angle-left.svg'); }
.slick-next { right: 0; background-image: url('../img/angle-right.svg'); }
.slick-dots { position: absolute; width: 100%; text-align: center; padding: 0; bottom: rem(5px); }
.slick-dots li { list-style-type: none; display: inline-block; }
.slick-dots li button { position: relative; width: rem(35px); height: rem(35px); border-radius: 100%; font-size: 0; margin: rem(0 7.5px); border: rem(3px) solid #FFF; background: transparent; }
.slick-dots li button:after { content: ""; display: block; position: absolute; top: rem(6px); left: rem(6px); width: rem(17px); height: rem(17px); border-radius: 100%; background-color: #FFF; opacity: 0; transition: opacity 0.5s; }
.slick-dots li.slick-active button:after { opacity: 1; }
.slick-dots li button:hover { opacity: 0.75; }
.content-slider .slick-prev { left: -28px }
.content-slider .slick-next { right: -28px }
.content-slider { margin: 0 -$grid-gutter-width/2; }
.content-slider--item { margin: 0 $grid-gutter-width/2; }
#content .slick-slider li { padding: 0; }
#content .slick-slider li:before { background-color: transparent; }


//Animatiertes Menüicon
.mainnav__menu-icon { width: rem(44px); height: rem(44px); position: relative; transform: rotate(0deg); transition: .5s ease-in-out; cursor: pointer; background: none; border: none; }
.mainnav__menu-icon span { display: block; position: absolute; height: rem(4px); width: rem(40px); background: #363489; opacity: 1; left: rem(2px); transform: rotate(0deg); transition: .25s ease-in-out; }
.mainnav__menu-icon span:nth-child(1) { top: 7px; }
.mainnav__menu-icon span:nth-child(2),.mainnav__menu-icon span:nth-child(3) { top: 19px; }
.mainnav__menu-icon span:nth-child(4) { top: 31px; }
.mainnav__menu-icon.open span:nth-child(1) { top: 18px; width: 0; left: 50%; }
.mainnav__menu-icon.open span:nth-child(2) { transform: rotate(45deg); }
.mainnav__menu-icon.open span:nth-child(3) { transform: rotate(-45deg); }
.mainnav__menu-icon.open span:nth-child(4) { top: 18px; width: 0; left: 50%; }
.mainnav__menu-icon:focus { outline: none; }

// Reset Navigation Lists
nav ul { margin: 0; padding: 0; }
nav ul li { list-style-type: none; margin: 0; padding: 0; }


/* ==========================================================================
   Project's custom styles
   ========================================================================== */

section.text a { text-decoration: underline; }

*:focus {
  outline-color: $brand-primary;
}

html {
  font-size: 62.5%;
}

html.no-scroll {
  overflow: hidden;
}

body {
  font-size: rem($font-size-base);
  font-family: $font-family-base;
}

h1, h2, h3, h4 {
  margin-top: 0;
  margin-bottom: rem(20px);
}

h1 {
  font-size: rem($font-size-h1 * 0.55);
  font-weight: 700;
  color: $headline-color;
}

h2 {
  font-size: rem($font-size-h2 * 0.6);
  font-weight: 700;
  color: $headline-color;
}

h3 {
  font-size: rem($font-size-h3 * 0.7);
  font-weight: 700;
  color: $headline-color;
}

h4 {
  font-size: rem($font-size-h4 * 0.9);
  font-weight: 700;
  color: $headline-color;
}

h5 {
  font-size: rem($font-size-h5);
  font-weight: 700;
  color: $headline-color;
}

p {
  font-size: rem($font-size-base);
  color: $text-color-dark;
  line-height: rem(30px);
}

p.large {
  font-size: rem($font-size-base-large*0.8);
  color: $text-color-dark;
  line-height: rem(36px*0.8);
}

#content ul {
  padding: 0 0 0 20px;
}

#content ul li {
  font-size: rem($font-size-base);
  color: $text-color-dark;
  line-height: rem(30px);
}

a {
  color: $theme-secondary;
  transition: all 0.3s;
}

a:hover {
  color: darken($theme-secondary,10%);
}

#content img {
  max-width: 100%;
}

section.text {
  padding: rem(50px 0);
}

section.text + section.text {
  padding-top: 0;
}

section.text + .white {
  padding-top: 0;
}

.themecolor {
  color: $theme-secondary;
}

a.mailadress {
  color: $text-color-dark;
  display: inline-block;
  border-bottom: 1px solid $theme-secondary;
}

button:focus,
a:focus {
  outline: transparent!important;
}

.btn {
  display: block;
  border: 2px solid;
  text-align: center;
  font-size: rem(20px);
  border-radius: 0;
  max-width: rem(300px);
  transition: all 0.3s;
  font-weight: 400;
}

.btn-white {
  border-color: #FFF;
  color: #FFF;
  box-shadow: inset 0 0 0 0 #FFF;
}

.btn-white:hover {
  background-color: #FFF;
  //box-shadow: inset 0 -66px 0 0 #FFF;
  color: $theme-secondary;
}

.btn-themecolor {
  border-color: $theme-secondary;
  color: $theme-secondary;
}

.btn-themecolor:hover {
  background: $theme-secondary;
  color: #FFF;
}

//Animiertes Unterstreichen von der Mitte ausgehend
.underline a {
  position: relative;
  color: #FFF;
  text-decoration: none;
}
.underline a:hover {
  color: #FFF;
}
.underline a:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 5px;
  bottom: -14px;
  left: 0;
  background-color: #FFF;
  visibility: hidden;
  transform: scaleX(0);
  transition: all 0.3s ease-in-out 0s;
}
.underline a:hover:before {
  visibility: visible;
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
}

section.custom-form .form-wrapper {
  margin: 50px 0;
}

section.custom-form .form-wrapper form {
  margin: 0 auto;
}

section.custom-form .form-wrapper.useBackgroundcolor {
  margin: 0 -20px;
  padding: 50px 20px;

  label, p {
    color: #FFF;
  }

  input {
    border: none;
    box-shadow: none;
  }

  textarea {
    border: none;
    box-shadow: none;
  }

  input[type="file"] {
    color: #FFF;
  }

  .btn {
    color: #FFF;
    border: 1px solid #FFF;
  }

}

section.custom-form .btn-themecolor {
  background: transparent;
  padding: rem(10px 15px);
  border-width: 1px;
  float: right;
}

section.custom-form .btn-themecolor:hover {
  color: $theme-primary;
}

section.custom-form input[type="text"] {
  height: 40px;
  border-radius: 0;
}

section.custom-form textarea {
  border-radius: 0;
}

section.custom-form select.form-control {
  height: 40px;
  border-radius: 0;
  box-shadow: none;
}


@media (max-width: 991px) {
  section.custom-form .form-wrapper form {
    width: 100%!important;
  }
}


/* Kleine Geräte (Tablets, 768px und breiter) */
@media (min-width: $screen-tablet) {

  h1 {
    font-size: rem($font-size-h1 * 0.65);
    font-weight: 700;
  }

  h2 {
    font-size: rem($font-size-h2 * 0.7);
  }

  h3 {
    font-size: rem($font-size-h3 * 0.8);
  }

  h4 {
    font-size: rem($font-size-h4);
  }

  p.large {
    font-size: rem($font-size-base-large);
    line-height: rem(36px);
  }

  .container--slim {
    padding: 0 10%;
  }

}

/* Mittlere Geräte (Desktop-PCs, 992px und breiter) */
@media (min-width: $screen-desktop) {

  h1 {
    font-size: rem($font-size-h1);
    font-weight: 700;
  }

  h2 {
    font-size: rem($font-size-h2);
  }

  h3 {
    font-size: rem($font-size-h3);
  }

  h4 {
    font-size: rem($font-size-h4);
  }

  .btn {
    padding: rem(10px 12px);
  }

  section.custom-form .form-wrapper.useBackgroundcolor {
    margin: 0;
  }

}

/* Große Geräte (Desktop-PCs, 1200px und breiter) */
@media (min-width: $screen-lg-desktop) {

  .container {
    width: 1170px;
  }

  .btn {
    padding: rem(17px 12px);
  }

}